/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import $ from 'jquery'
import 'particles.js'
import 'chart.js'
// import 'semantic-ui-css/semantic.min.css'

let deferredPrompt;

const ios = () => {
  if (typeof window === `undefined` || typeof navigator === `undefined`) return false;
  return /iPhone|iPad|iPod/i.test(navigator.userAgent || navigator.vendor || (window.opera && opera.toString() === `[object Opera]`));
};

// This ensures the button is manipulated only when it's ready in the DOM
document.addEventListener('DOMContentLoaded', () => {
  const installButtonLink = document.getElementById('installButtonLink');
  const installButton = document.getElementById('installButton');

  function handleInstallButtonLink() {
    if (!window.location.href.includes("install") && installButtonLink != null) {
      if (!isAppInstalled()) {
        installButtonLink.style.display = 'block';
        installButtonLink.addEventListener("click", (e) => {
          e.stopPropagation();
          window.location = "/install";
        });
      }
    }
  }

  if (ios()) {
    handleInstallButtonLink();
  } else {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      deferredPrompt = event;
      handleInstallButtonLink();
      if (window.location.href.includes("install") || installButtonLink == null) {
        installButton.addEventListener('click', () => {
          deferredPrompt.prompt();

          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }

            deferredPrompt = null;
          });
        });
      }
    });
  }
});


// Function to check if the PWA is already installed
function isAppInstalled() {
  return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
}


if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
    .then(function (reg) {
      console.log('Service worker registered!');
    });
}

window.jQuery = $
window.$ = $

function centerContentMobileNavigationToViewport() {

  const anchorlinks = document.querySelectorAll('.mobile-navigation a[href^="#"]');
  for (const item of anchorlinks) {

    item.addEventListener('click', (e) => {

      const hashval = item.getAttribute('href');
      const target = document.querySelector(hashval);
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
      history.pushState(null, null, hashval);
      e.preventDefault();

    })

  }
}

$(document).ready(function () {


  // $(document).on("mouseenter", ".comment", function(){
  //   console.log($(this.length))
  //   $(this).eq(0).find(".can-toggle").first().show();
  // });

  // $(document).on("mouseleave", ".comment", function(){
  //   $(this).eq(0).find(".can-toggle").first().hide();
  // });


  $(".collapse-vertical").click(function () {
    if (window.innerWidth > 768) {
      $(".nav-vertical").addClass("collapsed");
      $(".react-element__container").removeClass("col-xs-12 col-sm-10").addClass("expanded");
      $(".react-element__container.expanded").prepend('<span class="expand-vertical"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/><path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/></svg></span>');
    }
  });

  $(document).on("click", ".expand-vertical", function () {
    if (window.innerWidth > 768) {
      $(".nav-vertical").removeClass("collapsed");
      $(".react-element__container").removeClass("expanded").addClass("col-xs-12 col-sm-10");
      $(".expand-vertical").remove();
    }
  });

  $(".lessons-link").click(function (e) {
    e.stopPropagation();
    window.location = $("#lessons a").attr("href");

  });


  $(".meetings-link").click(function (e) {
    e.stopPropagation();
    $(".book-group").click();

  });

  $(".social_mobile img").click(function (e) {
    $(".mobile#social_links").toggle(400);
  });


  $(".help-link").click(function (e) {
    e.stopPropagation();
    window.location = $(".addiction_progress .btn_card").click();

  });




  $('#show-group-modal').click(function (e) {
    e.preventDefault
    $('.book-group').click()
  })
  $('.dropdown-toggle').dropdown();
  jQuery('#badge-modal-1').on('hidden.bs.modal', function (e) {

    if (window.location.href.includes('summary')) {

      const tagElements = (inputElem, group) => {
        if (inputElem.nextElementSibling && inputElem.nextElementSibling.nodeName === 'LABEL') {
          inputElem.setAttribute('data-group', group)
          inputElem.nextElementSibling.setAttribute('data-group', group)
        } else if (
          inputElem.prevElementSibling &&
          inputElem.prevElementSibling.nodeName === 'LABEL'
        ) {
          inputElem.setAttribute('data-group', group)
          inputElem.nextElementSibling.setAttribute('data-group', group)
        } else {
          inputElem.setAttribute('data-group', group)
          inputElem.parentElement.setAttribute('data-group', group)
        }
      }

      const radioListener = (inputElem) => {
        document
          .querySelectorAll(`label[data-group="${inputElem.getAttribute('data-group')}"]`)
          .forEach((label) => {
            label.classList.remove('btn-selected')
          })

        if (inputElem.nextElementSibling && inputElem.nextElementSibling.nodeName === 'LABEL')
          inputElem.nextElementSibling.classList.add('btn-selected')
        else if (inputElem.prevElementSibling && inputElem.prevElementSibling.nodeName === 'LABEL')
          inputElem.nextElementSibling.classList.add('btn-selected')
        else inputElem.parentElement.classList.add('btn-selected')
      }

      const radios = Array.from(document.querySelectorAll('input[type="radio"]'))
      const numbers = radios.slice(0, 5)
      const middle = radios.slice(5, 8)
      const emotions = radios.slice(8, radios.length)

      numbers.forEach((input) => {
        tagElements(input, 'a')
      })

      middle.forEach((input) => {
        tagElements(input, 'b')
      })

      emotions.forEach((input) => {
        tagElements(input, 'c')
      })

      radios.forEach((input) => input.addEventListener('click', () => radioListener(input)))
    }
  })


  if ($("#points-modal").length > 0) {
    showRewardIcon();
  } else {
    showRewardModal();
  }

  jQuery('#badge-modal-1').on('shown.bs.modal', function (e) {
    useConfetti(this)
  })

  $('#points-modal').on('hidden.bs.modal', function (e) {
    showRewardModal();
  })

  $('#points-modal').on('shown.bs.modal', function (e) {
    useConfetti(this)
  })

  function useConfetti(obj) {
    party.confetti(obj, {
      count: party.variation.range(1000, 1300),
      shapes: ["circle", "roundedSquare", "star"],
      speed: party.variation.range(50, 300),
    });
    party.settings.zIndex = 1045;
    party.settings.gravity = 300;
  }

  function showRewardModal() {
    if ($("#badge-modal-1").length > 0) {
      $(".badges").each(function (i, element) {
        $(element).modal('show');
        //console.log($(element).data('user-reward-id'));
        var userRewardId = $(element).data('user-reward-id');

        //console.log($(element).data('current-user-id'));
        var currentUserId = $(element).data('current-user-id');

        $.ajax({
          type: "POST",
          url: '/api/v1/rewards',
          data: {
            "current_user_id": currentUserId,
            "user_reward_id": userRewardId
          },
          success: function () {
            console.log("uploaded");
          }
        });
      });
    }
  }
  $(".modal.fade").on("click", ".close", function () {
    $(".modal-backdrop").hide();
  })

  function showRewardIcon() {
    if (!["/wheel", "/intake"].includes(window.location.pathname)) {
      $(".points").each(function (i, element) {
        $(element).modal('show');
        //console.log($(element).data('user-reward-id'));
        var userRewardId = $(element).data('user-reward-id');

        //console.log($(element).data('current-user-id'));
        var currentUserId = $(element).data('current-user-id');
        setTimeout(function () { $(element).modal('hide'); }, 4000);
        $.ajax({
          type: "POST",
          url: '/api/v1/rewards',
          data: {
            "current_user_id": currentUserId,
            "user_reward_id": userRewardId
          },
          success: function () {
            console.log("uploaded");
          }
        });
      });
    }
  }

  centerContentMobileNavigationToViewport();

});
